import { emitter } from '@/main'
import { language } from '@/services/language'
import { RouteRecordRaw, createWebHistory, createRouter } from 'vue-router'

declare module 'vue-router' {
  interface RouteMeta {
    // is optional
    isPublic?: boolean
    beta?: boolean
  }
}

// Composables
export const routes: RouteRecordRaw[] = [
  // OIDC Framework first ..
  {
    path: '/oidc-callback', // Needs to match redirectUri in you oidcSettings
    name: 'oidcCallback',
    component: () => import('@/views/OidcCallback.vue'),
    props: (route) => ({ query: route.query ? route.query : {} }),
    meta: { isPublic: true }
  },

  // Stammdaten
  {
    path: '/:lang/masterdata/find',
    name: 'masterdata_find',
    component: () => import('@/views/masterdata/find.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/masterdata',
    name: 'masterdata_search',
    component: () => import('@/views/masterdata/search.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/legalEntities/search',
    name: 'masterdata_legalEntities_search',
    component: () => import('@/views/legalEntities/search.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/legalEntities/create',
    name: 'masterdata_legal_entities_create',
    component: () => import('@/views/legalEntities/create.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/legalEntities/:id/:tab',
    name: 'masterdata_legal_entities_edit',
    component: () => import('@/views/legalEntities/edit.vue'),
    props: (route: any) => ({
      id: route.params.id,
      tab: route.params.tab
    })
  },
  {
    path: '/:lang/localUnits/search',
    name: 'masterdata_localUnits_search',
    component: () => import('@/views/localUnits/localUnitsSearch.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/dairies',
    name: 'masterdata_dairies_search',
    component: () => import('@/views/dairies/search.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/dairies/create',
    name: 'masterdata_dairies_create',
    component: () => import('@/views/dairies/create.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/dairies/:id/:tab',
    name: 'masterdata_dairies_edit',
    component: () => import('@/views/dairies/edit.vue'),
    props: (route: any) => ({
      id: parseInt(route.params.id),
      tab: route.params.tab
    })
  },
  {
    path: '/:lang/farms',
    name: 'masterdata_farms_search',
    component: () => import('@/views/farms/search.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/farms/create',
    name: 'masterdata_farms_create',
    component: () => import('@/views/farms/create.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/farms/:id/:tab',
    name: 'masterdata_farms_edit',
    component: () => import('@/views/farms/edit.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id,
      tab: route.params.tab
    })
  },
  {
    path: '/:lang/milkQualityBenchmarks/infoPage',
    name: 'milkQualityBenchmarks_infoPage',
    component: () => import('@/views/milkQualityBenchmarks/infoPage.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/milkQualityBenchmarks/chart',
    name: 'milkQualityBenchmarks_chart',
    component: () => import('@/views/milkQualityBenchmarks/chart.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/milkQualityBenchmarks/read',
    name: 'milkQualityBenchmarks_read',
    component: () => import('@/views/milkQualityBenchmarks/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/milkQualities/read',
    name: 'milkqualities_read',
    component: () => import('@/views/milkQualities/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/milkQualities/content/read',
    name: 'content_search',
    component: () => import('@/views/milkQualities/content/search.vue'),
    props: (route) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/milkQualities/:sampleUrl/:primaryKeyLab/',
    name: 'milkqualities_details',
    component: () => import('@/views/milkQualities/edit.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      sampleUrl: route.params.sampleUrl,
      primaryKeyLab: route.params.primaryKeyLab
    })
  },
  // Milchproduktion
  {
    path: '/:lang/productionForms/read',
    name: 'productionForms_read',
    component: () => import('@/views/mpd/productionForms/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/productionForms/:id',
    name: 'productionForms_edit',
    component: () => import('@/views/mpd/productionForms/edit.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/productionForms/reports/overview/read',
    name: 'production_forms_reports',
    component: () => import('@/views/mpd/productionForms/reports/overview/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/productionFormRows/farmer/read',
    name: 'production_form_rows_read_farmer',
    component: () => import('@/views/mpd/productionFormRows/read.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      isFarmer: true
    })
  },
  {
    path: '/:lang/productionFormRows/read',
    name: 'production_form_rows_read',
    component: () => import('@/views/mpd/productionFormRows/read.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      isFarmer: false
    })
  },
  {
    path: '/:lang/productionForms/multiEdit/read',
    name: 'productionForms_multiEdit_read',
    component: () => import('@/views/mpd/productionFormMultiEdit/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/productionFormRows/subsidyApproval',
    name: 'productionFormRows_subsidy_Approval',
    component: () => import('@/views/mpd/productionFormRows/subsidyApproval.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/contracts/read',
    name: 'contracts_read',
    component: () => import('@/views/mpd/contracts/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/contracts/create',
    name: 'contracts_create',
    component: () => import('@/views/mpd/contracts/create.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/contracts/:id',
    name: 'contract_edit',
    component: () => import('@/views/mpd/contracts/edit.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  // Milchverwertung
  {
    path: '/:lang/products/read',
    name: 'products_read',
    component: () => import('@/views/mbh/products/products.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/products/create',
    name: 'product_create',
    component: () => import('@/views/mbh/products/createProduct.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/products/reports/read',
    name: 'product_reports',
    component: () => import('@/views/mbh/products/reports.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/products/:id',
    name: 'product_write',
    component: () => import('@/views/mbh/products/editProduct.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/productGroups/:id',
    name: 'productGroups_write',
    component: () => import('@/views/mbh/productGroups/editProductGroup.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/productGroups',
    name: 'productGroups_read',
    component: () => import('@/views/mbh/productGroups/productGroups.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/processingFormTemplates/read',
    name: 'processingFormTemplates_read',
    component: () => import('@/views/mbh/processingFormTemplates/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/processingFormTemplates/create',
    name: 'processingFormTemplates_create',
    component: () => import('@/views/mbh/processingFormTemplates/create.vue')
  },
  {
    path: '/:lang/processingFormTemplates/:id/:tab',
    name: 'processingFormTemplates_update',
    component: () => import('@/views/mbh/processingFormTemplates/edit.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: parseInt(route.params.id),
      tab: route.params.tab
    })
  },
  {
    path: '/:lang/processingForms/read',
    name: 'processingForms_read',
    component: () => import('@/views/mbh/processingForms/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/processingForms/:id',
    name: 'processingForms_edit',
    component: () => import('@/views/mbh/processingForms/edit.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/processingForms/:id/1',
    name: 'processingForms_edit_form_step_one',
    component: () => import('@/views/mbh/processingForms/editFormStepOne.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/processingForms/:id/revisions',
    name: 'applicationForms_revisions',
    component: () => import('@/views/mbh/processingForms/revisions.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/processingForms/:id/2',
    name: 'processingForms_edit_form_step_two',
    component: () => import('@/views/mbh/processingForms/editFormStepTwo.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/processingForms/:id/3',
    name: 'processingForms_edit_form_step_three',
    component: () => import('@/views/mbh/processingForms/editFormStepThree.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/processingForms/:id/4',
    name: 'processingForms_edit_form_step_four',
    component: () => import('@/views/mbh/processingForms/editFormStepFour.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/processingForms/reports/overview',
    name: 'processingForm_reports_overview',
    component: () => import('@/views/mbh/processingForms/reports/overview.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/processingForms/reports/multi',
    name: 'processingForm_reports_multi',
    component: () => import('@/views/mbh/processingForms/reports/multi.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/processingForms/reports/plausibility',
    name: 'processingForm_plausibility_read',
    component: () => import('@/views/mbh/processingForms/reports/plausibility.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/segmentationRelations/read',
    name: 'segmentationRelations_read',
    component: () => import('@/views/segmentationRelations/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/segmentationRelations/create',
    name: 'segmentationRelations_create',
    component: () => import('@/views/segmentationRelations/create.vue')
    //props: (route:any) => ({ params: route.params })
  },
  {
    path: '/:lang/segmentationRelations/:id',
    name: 'segmentationRelations_edit',
    component: () => import('@/views/segmentationRelations/edit.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/segmentationForms/read',
    name: 'segmentationForms_read',
    component: () => import('@/views/segmentationForms/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/segmentationForms/:id/1',
    name: 'segmentationForms_edit_step_one',
    component: () => import('@/views/segmentationForms/editStepOne.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/segmentationForms/:id/2',
    name: 'segmentationForms_edit_step_two',
    component: () => import('@/views/segmentationForms/editStepTwo.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/segmentationForms/:id',
    name: 'segmentationForms_edit',
    component: () => import('@/views/segmentationForms/edit.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/segmentationForms/reports/overview',
    name: 'segmentationForms_reports_overview',
    component: () => import('@/views/segmentationForms/reports/overview.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/segmentationForms/reports/inputOutput',
    name: 'segmentationForms_reports_inputOutput',
    component: () => import('@/views/segmentationForms/reports/inputOutput.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/segmentationForms/reports/differences',
    name: 'segmentationForms_reports_differences',
    component: () => import('@/views/segmentationForms/reports/differences.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  // Zulagen (in Milchproduktion und Milchverwertung)
  {
    path: '/:lang/subsidyInvoices/production',
    name: 'subsidy_invoices_production',
    component: () => import('@/views/subsidy/invoice/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {}, subsidyType: 'PRODUCTION' })
  },
  {
    path: '/:lang/subsidyInvoices/processing',
    name: 'subsidy_invoices_processing',
    component: () => import('@/views/subsidy/invoice/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {}, subsidyType: 'PROCESSING' })
  },
  {
    path: '/:lang/subsidyConfigs',
    name: 'subsidyConfigs_edit',
    component: () => import('@/views/subsidy/subsidyConfigs/edit.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/subsidyCreditors/production/editDialog', //   /:lang/subsidyCreditors/production|processing
    name: 'subsidyCreditor_production_edit',
    component: () => import('@/views/subsidy/creditor/edit.vue'),
    props: (route: any) => ({
      ...{ query: route.query ? route.query : {} },
      ...{ creditorType: 'production' }
    })
  },
  {
    path: '/:lang/subsidyCreditors/processing/editDialog', //   /:lang/subsidyCreditors/production|processing
    name: 'subsidyCreditor_processing_edit',
    component: () => import('@/views/subsidy/creditor/edit.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      creditorType: 'processing'
    })
  },
  // Administration
  {
    path: '/:lang/applicationUsers/read',
    name: 'applicationUsers_read',
    component: () => import('../views/administration/applicationUsers/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/applicationUsers/create',
    name: 'applicationUsers_create',
    component: () => import('../views/administration/applicationUsers/create.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/applicationUsers/:id',
    name: 'applicationUsers_edit',
    component: () => import('../views/administration/applicationUsers/edit.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/translations',
    name: 'translations_read',
    component: () => import('@/views/translations/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/translations/:id',
    name: 'translations_write',
    component: () => import('@/views/translations/edit.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/translations/create',
    name: 'translation_create',
    component: () => import('@/views/translations/create.vue')
  },
  {
    path: '/:lang/documents/inbox/read',
    name: 'inbox_read',
    component: () => import('@/views/documents/inbox/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/messages/inbox/read',
    name: 'messages_read',
    component: () => import('@/views/messages/inbox/read.vue'),
    props: (route) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/messages/sent/read',
    name: 'messages_sent_read',
    component: () => import('@/views/messages/sent/read.vue'),
    props: (route) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/subscriptions/read',
    name: 'messages_subscriptions_read',
    component: () => import('@/views/messages/subscriptions/read.vue')
    // props: (route) => ({ query: route.query ? route.query : {}, tab: route.params.tab })
  },
  {
    path: '/:lang/messageConfigs/read',
    name: 'messages_configs_read',
    component: () => import('@/views/messages/configs/read.vue')
  },
  {
    path: '/:lang/contacts/searchMasterData',
    name: 'contacts_searchMasterData',
    component: () => import('@/views/contacts/searchMasterData.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/contacts/searchIndependent',
    name: 'contacts_searchIndependent',
    component: () => import('@/views/contacts/searchIndependent.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/contacts/:id/:tab',
    name: 'contacts_edit',
    component: () => import('@/views/contacts/edit.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      contactId: parseInt(route.params.id),
      tab: route.params.tab
    })
  },
  {
    path: '/:lang/toasts',
    name: 'toasts',
    component: () => import('@/views/Toasts.vue')
  },
  {
    path: '/:lang/issues/search',
    name: 'issues_read',
    component: () => import('@/views/issues/search.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      siblingGuid: route.params.siblingGuid
    })
  },
  {
    path: '/:lang/issues/create',
    name: 'issues_create',
    component: () => import('@/views/issues/create.vue')
    //props: (route:any) => ({ params: route.params })
  },
  {
    path: '/:lang/issues/:id',
    name: 'issues_edit',
    component: () => import('@/views/issues/edit.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      id: route.params.id
    })
  },
  {
    path: '/:lang/labelOrders',
    name: 'label_orders_read',
    component: () => import('@/views/label/labelOrders/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/labelOrders/dairy/',
    name: 'label_orders_edit',
    component: () => import('@/views/label/labelOrders/edit.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      dairyIdent: route.params.dairyIdent
    })
  },
  {
    path: '/:lang/labelData/detail/:localUnitId',
    name: 'label_data_details',
    component: () => import('@/views/label/labelData/pivot/detail.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      localUnitId: parseInt(route.params.localUnitId)
    })
  },
  {
    path: '/:lang/labelData',
    name: 'label_data_read',
    component: () => import('@/views/label/labelData/pivot/read.vue'),
    props: (route: any) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/profile/:tab',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    props: (route: any) => ({
      query: route.query ? route.query : {},
      tab: route.params.tab
    })
  },
  {
    // default route without
    path: '/:lang/smpEncashments/search',
    name: 'smpEncashments_search',
    component: () => import('@/views/encashment/smpEncashments/search.vue'),
    props: (route) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/smpEncashmentRows/read',
    name: 'smpEncashmentRows_read',
    component: () => import('@/views/encashment/smpEncashmentRows/read.vue'),
    props: (route) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/smpEncashmentRows/invoice',
    name: 'smpEncashmentRows_invoice',
    component: () => import('@/views/encashment/smpEncashmentRows/invoice.vue'),
    props: (route) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/encashmentConfigs/read',
    name: 'smpEncashment_read',
    component: () => import('@/views/encashment/encashmentConfigs/read.vue'),
    props: (route) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/invoices/read',
    name: 'invoices_read',
    component: () => import('@/views/invoices/invoices/read.vue'),
    props: (route) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/invoiceItems/read',
    name: 'invoiceItems_read',
    component: () => import('@/views/invoices/invoiceItems/read.vue'),
    props: (route) => ({ query: route.query ? route.query : {} })
  },
  {
    path: '/:lang/invoiceTemplates/read',
    name: 'invoicesTemplates_read',
    component: () => import('@/views/invoices/invoiceTemplates/read.vue'),
    props: (route) => ({ query: route.query ? route.query : {} })
  },
  {
    // default route when the user is not fully loaded
    path: '/:lang/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: { isPublic: true }
  },
  {
    // default route with lang
    path: '/:lang/home',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    // default route without
    path: '/:lang/dummy',
    name: 'dummy',
    component: () => import('@/views/Login.vue'),
    meta: { isPublic: true }
  },
  {
    // default route without
    path: '/*',
    name: 'catchall',
    component: () => import('@/views/Login.vue'),
    meta: { isPublic: true }
  }
]
/* ! the route name must be unique! otherwise the router will not match the route ! */

export const router = createRouter({
  history: createWebHistory(),
  routes
})
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { authService } from '@/services/auth.service'
import { useSettingsStore } from '@/store/SettingsStore'

/* /de/abcd3sws matches the route '*' and will be forwardet to /de/login     */
function defaultAsHome(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): any {
  console.log('defaultAsBlankPage ', to, from)
  if (to.name == 'Default' && /to.fullPath/.test('/login')) {
    if (to.params?.lang) {
      next({ path: '/' + to.params?.lang + '/login' })
    } else {
      next({ path: '/login' })
    }
  } else {
    next()
  }
}

async function oidcSecurityInterceptor(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<any> {
  console.log('oidcinterceptor testing', to, from)
  if (to.meta?.isPublic === true) {
    console.log('public url found testing', to, from)
    next()
  } else {
    console.log('secured url, continue oidcinterceptor else', to, from)
    try {
      const user = await authService.getUser()
      if (user) {
        console.log('router oidcinterceptor user loaded from authservice', to, from)
        next()
      } else {
        console.log('router oidcinterceptor no user found', to, from)
        authService
          .signinSilent()
          .then(() => {
            console.log('router oidcinterceptor signinRedirect success')
          })
          .catch(() => {
            console.error('router oidcinterceptor signinRedirect error')
            authService.signinRedirect().catch((err) => {
              console.error('router oidcinterceptor no user found', err)
              emitter.emit('toastError', 'idp_unavailable')
            })
          })
        next({ path: '/' + to.params?.lang + '/login' })
      }
    } catch (e) {
      console.log('oidcRouteInterceptor failed', e)
      next({ path: '/' + to.params?.lang + '/login' })
    }
  }
}

function debugIntercepter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): any {
  // detect beta mode ?beta=true |beta=false
  // TODO: klären, ob Beta nur für bestimmte Benutzer verfügbar sein soll
  // if (typeof to.query.beta != 'undefined' && privileges.has({ path: '/beta', permission: 'show' })) {
  console.log('router debugIntercepter testing', to, from)
  const settingsStore = useSettingsStore()

  if (typeof to.query.beta != 'undefined') {
    settingsStore.setBeta(to.query.beta === 'true')
    emitter.emit('reloadNav')
  }
  if (typeof to.query.debug != 'undefined') {
    if (['true', 'false'].includes(to.query.debug as string)) {
      settingsStore.setDebug(to.query.debug === 'true')
      emitter.emit('toastSuccess', 'set debug to: ' + to.query.debug)
    }
    if (to.query.debug == 'lang') {
      language.showLanguageKeys()
      emitter.emit('toastSuccess', 'showLanguageKeys')
    }
  }
  next()
}
router.beforeEach(oidcSecurityInterceptor)
router.beforeEach(language.routeIntercepter)
router.beforeEach(debugIntercepter)
router.beforeEach(defaultAsHome)

router.onError((error) => {
  console.error('RouteHelper onError', error)
  /* a new pod has been deployed in K8s, the requested junk file known from an ancient application doesent exists anymore. so reload the whole application */
  if (/Failed to fetch dynamically imported module/i.test(error.message)) {
    window.location.reload()
  }
})
