import { getLocale } from '@/utils/LocaleUtils'

/**
 * Returns true if the targetDate is between the validFrom and validUntil dates.
 */
export function isValid(inValidFrom?: string | Date, inValidUntil?: string | Date, targetDate: Date = new Date()): boolean {
  const validFrom = inValidFrom ? new Date(inValidFrom) : undefined
  const validUntil = inValidUntil ? new Date(inValidUntil) : undefined
  if (!validFrom) {
    console.warn('isValid called with invalid validFrom', inValidFrom, inValidUntil)
    return false
  }
  const isBefore = validFrom < targetDate
  if (!validUntil) {
    return isBefore
  }
  const isAfter = validUntil > targetDate
  return isBefore && isAfter
}

/**
 * Formats the given date according to the locale and options.
 */
export function formatDate(date: Date, locale?: Intl.Locale, options?: Intl.DateTimeFormatOptions): string {
  return new Intl.DateTimeFormat(locale ?? getLocale(), options).format(date)
}

/**
 * Returns period of years.
 *
 * @param startingYear Starting year.
 * @param startingMonth Starting month index (0 = January).
 * @param durationYears Duration in years.
 * @returns Array with two dates: start and end of the period.
 */
export function toPeriodOfYears(startingYear: number, startingMonth: number, durationYears: number): Date[] {
  const startOfLastSeason = new Date(startingYear, startingMonth, 1)
  const endOfLastSeason = new Date(startingYear + durationYears, startingMonth, 0) // 0 = last day of previous month
  return [startOfLastSeason, endOfLastSeason]
}
