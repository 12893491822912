import { isValidString } from '@/utils/TypeGuards'
import { getCountryCodeFromPath } from '@/utils/PathUtils'

const defaultLocaleCode: Intl.UnicodeBCP47LocaleIdentifier = 'de-ch'
const defaultLocale: Intl.Locale = new Intl.Locale(defaultLocaleCode)
const acceptedLocaleCodes: readonly Intl.UnicodeBCP47LocaleIdentifier[] = ['de-ch', 'fr-ch', 'it-ch']

/** Returns {@link Intl.Locale} of the user. */
export function getLocale(): Intl.Locale {
  // priority by URL
  const countryCodeFromPath: string | undefined = getCountryCodeFromPath()
  if (isValidString(countryCodeFromPath)) {
    const localeCode: Intl.UnicodeBCP47LocaleIdentifier = countryCodeToAcceptedLocales(countryCodeFromPath)
    return new Intl.Locale(localeCode)
  }

  // priority by localStorage
  // todo: implement in case locale or language gets one day saved localeStorage

  // priority by browser
  const localeCodeFromNavigator = getFirstAcceptedLocaleCodeFromNavigator()
  if (isValidString(localeCodeFromNavigator)) {
    return new Intl.Locale(localeCodeFromNavigator)
  }

  return defaultLocale
}

function isAcceptedLocaleCode(localeCode: string): boolean {
  return acceptedLocaleCodes.includes(localeCode.toLowerCase())
}

/**
 * Returns the first accepted locale code from the navigator or undefined if no accepted locale code is found.
 */
function getFirstAcceptedLocaleCodeFromNavigator(): (typeof acceptedLocaleCodes)[number] | undefined {
  for (const localeCode of navigator.languages) {
    if (isAcceptedLocaleCode(localeCode)) {
      return localeCode
    }
  }
}

/**
 * Returns the accepted locale for the given country code or the default locale if the country code is not accepted.
 * @param countryCode
 */
function countryCodeToAcceptedLocales(countryCode: string): (typeof acceptedLocaleCodes)[number] {
  const possibleLocaleCode = countryCode.toLowerCase() + '-ch'
  if (isAcceptedLocaleCode(possibleLocaleCode)) {
    return possibleLocaleCode
  }
  return defaultLocaleCode
}
