/*
 * Type guards and validators (mixed) that can be used for runtime type checks.
 */

import moment from 'moment'

/**
 * Type guard for number.
 * @apiNote Will return false for NaN or Infinity.
 * @returns true if the value is a number, false otherwise.
 */
export function isNumber(value: unknown): value is number {
  // proposal was declined by typescript team because Number('') returns '0' which is a number
  // isNaN('') (respectively isNaN(Number('')) returns therefore false
  return typeof value === 'number' && isFinite(value)
}

/**
 * Type guard for integer.
 * @apiNote Will return false for NaN or Infinity.
 * @returns true if the value is an integer, false otherwise.
 */
export function isInteger(value: unknown): value is number {
  // proposal was declined by typescript team because isInteger is not purely a type guard, since e.g. 1.3 is a number but not an integer
  return Number.isInteger(value)
}

/**
 * Type guard for safe integer.
 * @apiNote Will return false for NaN or Infinity.
 * @returns true if the value is a safe integer, false otherwise.
 */
export function isSafeInteger(value: unknown): value is number {
  // proposal was declined by typescript team because isSafeInteger is not purely a type guard
  return Number.isSafeInteger(value)
}

/**
 * Type guard for non-negative safe integers. (0, 1, 2, ...)
 * @apiNote Will return false for NaN or Infinity.
 * @returns true if the value is a safe integer above or equal zero, false otherwise.
 */
export function isNaturalSafeInteger(value: unknown): value is number {
  return isSafeInteger(value) && value >= 0
}

/**
 * Type guard for Dates.
 * @param value Can be any date format (e.g. ISO string), epochMilli timestamp or Date object.
 * @apiNote Will return false for invalid dates.
 * @returns true if the value is representable as a Date, false otherwise.
 */
export function isValidDate(value: Date | string | number): value is Date {
  return isSafeInteger(new Date(value).getTime())
}

/**
 * TypeGuard - Check if a string is empty
 */
export function isEmptyString(value: string): value is '' {
  return value.trim() == ''
}

/**
 * TypeGuard - Check if value is a string
 */
export function isString(value: unknown): value is string {
  return typeof value === 'string' || value instanceof String
}

/**
 * Type guard for strings.
 * @apiNote Multiple spaces count as empty.
 * @returns true if the value is a non-empty string, false otherwise.
 */
export function isValidString(value: unknown): value is string {
  return isString(value) && !isEmptyString(value)
}

/**
 * Check if given value is a valid ISO date.
 */
export function isValidISODate(value: unknown): value is ReturnType<typeof Date.prototype.toISOString> {
  if (!isValidString(value)) {
    return false
  }
  const byMoment: boolean = moment(value, moment.ISO_8601, true).isValid()
  return byMoment && isValidDate(value)
}

/**
 * Check if a value is an object (valid = not null nor array, since null and arrays is typeof object)
 */
export function isValidObject(o: unknown): o is object {
  return o instanceof Object && !(o instanceof Array)
}

/**
 * TypeGuard - Check if an object has keys
 */
export function hasKeys(o: object): o is Record<string, unknown> {
  return Object.keys(o).length > 0
}

export function isNull(value: unknown): value is null {
  return value === null
}

export function isUndefined(value: unknown): value is undefined {
  return value === undefined
}

export function isNullOrUndefined(value: unknown): value is null | undefined {
  return isNull(value) || isUndefined(value)
}
