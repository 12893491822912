import { ComposerTranslation, createI18n } from 'vue-i18n'
import { messageCompiler } from '@/views/translations/messageCompiler'

export const i18n = createI18n({
  globalInjection: true, // injects "this.$t" into vue components
  legacy: false,
  messageCompiler,
  locale: 'de',
  fallbackLocale: 'de'
})

/*
 * This is hack to make the $t function as type available in the Vue 3 components.
 * Currently vue-i18n (3rd party package to provide translations) does not provide a clean (=typed) way to access the $t function in Vue 3 components.
 * It seems that vue-i18n relies on deprecated features such a mixins. The issue ( https://github.com/intlify/vue-i18n/issues/1384 ) is still open.
 * Todo: Remove this "hack" and use the official way once announced (subscribe to the issue). Maybe use useI18nStore (our one) + translationService or the "official way" using useI18n by vue-i18n, see https://vue-i18n.intlify.dev/guide/migration/vue3#migration-to-composition-api-from-legacy-api.
 */
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    /**
     * Translates given `key` to the current language.
     *
     * @deprecated There is no consents (yet) what to use instead. Official way is to use `const { t } = useI18n()` as described in https://vue-i18n.intlify.dev/guide/migration/vue3#migration-to-composition-api-from-legacy-api
     */
    $t: ComposerTranslation
  }
}
