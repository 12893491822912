/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */
import DbmDatePicker from '@/components/dbmDatePicker.vue'

export const protocol: string = process.env.NODE_ENV === 'development' ? 'http://' : 'https://'
export const apiURL: string = process.env.NODE_ENV === 'development' ? '/api' : 'https://' + location.hostname + '/api'
export const idpURL: string = process.env.NODE_ENV === 'development' ? '/auth' : 'https://' + location.hostname + '/auth'
export const isDev: boolean = process.env.NODE_ENV === 'development'

// Components
import App from './App.vue'
// Composables
import { createApp } from 'vue'
export const app = createApp(App)

import routeHelper from './mixins/routeHelper'
app.mixin(routeHelper)

export function getApp() {
  return app
}
// Plugins store router etc
import { registerPlugins } from '@/plugins'
registerPlugins(app)

import mitt from 'mitt'
/*
type Events = {
  initialized?: string // when the mainscript is done
  reloadNav?: string
  oidcUserLoaded?: string
  oidcUserUnloaded?: string
  oidcSilentRenewError?: string
  privileges_new?: string
  loadHistory?: string
  openDownloadInfoDialog?: string
  backendUnAuthorized?: string
  toastSuccess?: string
  toastWarning?: string
  toastError?: string
}

type Events = {
  type: string
  payload: string
}*/
//export let emitter: Emitter<Events> = mitt<Events>()
export const emitter = mitt()

// https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
/* i18n wird im Router geladen wenn die Route zum ersten mal auf /de gepusht wird */
import { i18n } from '@/i18n'
app.use(i18n)
//app.use(VueI18n, i18n)

import { configAxios } from './services/axios'
import axios from 'axios'
import VueAxios from 'vue-axios'
configAxios(axios)
app.use(VueAxios, axios)

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
app.component('VueDatePicker', VueDatePicker)
app.component('DbmDatePicker', DbmDatePicker)

//app.provide('axios', axios) // provide 'axios'
//app.config.globalProperties.axios = axios
app.mount('#app')

//register toaster
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

//highcharts
import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'
More(Highcharts)
import HighchartsVue from 'highcharts-vue'
app.use(HighchartsVue)

app.use(Toast)

emitter.emit('initialized', 'bueno')
