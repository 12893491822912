import { defineStore } from 'pinia'

const oidc_keycloak_dev = {
  authority: 'http://localhost:8198/auth/realms/dbm',
  clientId: 'dbm-webapp',
  redirectUri: 'http://localhost:8080/oidc-callback',
  popupRedirectUri: 'http://localhost:8080/oidc-popup-callback',
  responseType: 'code',
  scope: 'openid profile',
  automaticSilentRenew: true,
  automaticSilentSignin: true,
  silentRedirectUri: 'http://localhost:8080/silent-renew-oidc.html'
}
const oidc_keycloak_prod = {
  authority: 'https://' + location.hostname + '/auth/realms/dbm',
  clientId: 'dbm-webapp',
  redirectUri: 'https://' + location.hostname + '/oidc-callback',
  popupRedirectUri: 'https://' + location.hostname + '/oidc-popup-callback',
  responseType: 'id_token token',
  scope: 'openid',
  automaticSilentRenew: true,
  automaticSilentSignin: true,
  silentRedirectUri: 'https://' + location.hostname + '/silent-renew-oidc.html'
}
const oidc_agate_integration = {
  authority: 'https://idp-rf.agate.ch/auth/realms/agate',
  clientId: 'milchdb-rf',
  redirectUri: 'https://' + location.hostname + '/oidc-callback',
  popupRedirectUri: 'https://' + location.hostname + '/oidc-popup-callback',
  responseType: 'code',
  scope: 'openid loginid',
  automaticSilentRenew: true,
  automaticSilentSignin: true,
  silentRedirectUri: 'https://' + location.hostname + '/silent-renew-oidc.html'
}
const oidc_agate_prod = {
  authority: 'https://idp.agate.ch/auth/realms/agate',
  clientId: 'milchdb',
  redirectUri: 'https://' + location.hostname + '/oidc-callback',
  popupRedirectUri: 'https://' + location.hostname + '/oidc-popup-callback',
  responseType: 'code',
  scope: 'openid loginid',
  automaticSilentRenew: true,
  automaticSilentSignin: true,
  silentRedirectUri: 'https://' + location.hostname + '/silent-renew-oidc.html'
}

export const useSettingsStore = defineStore('settingsStore', {
  persist: true,
  state: () => ({
    showDebug: false,
    showBeta: process.env.NODE_ENV === 'development' ? true : false,
    oidc: process.env.NODE_ENV === 'development' ? 'keycloak' : window.location.hostname.includes('test') ? 'keycloak' : 'agate' // keycloak | agate
  }),
  getters: {
    getBeta: (state): boolean => state.showBeta,
    getDebug: (state): boolean => state.showDebug,
    getOIDC: (state) => state.oidc,
    getOIDC_config: (state) => {
      if (process.env.NODE_ENV === 'development') return oidc_keycloak_dev
      if (state.oidc == 'agate') return window.location.hostname.includes('prod') ? oidc_agate_prod : oidc_agate_integration
      if (state.oidc == 'keycloak') return oidc_keycloak_prod
      return null
    }
  },
  actions: {
    setDebug(debug: boolean) {
      this.showDebug = debug
    },
    setBeta(beta: boolean) {
      this.showBeta = beta
    },
    setOIDC(oidc: string) {
      this.oidc = oidc
    }
  }
})
