import { language } from '@/services/language'
import { isoDatePattern } from '@/utils/regexPatterns'

/* build to interpolate zalando error messages like text with an array of params.
  formatter.interpolate(error.response.data.details, error.response.data.placeholders)

  or any text like so
  formatter.interpolate('my custom $1 is a $2 ',['car','Ferrari'])
*/
export class CustomFormatter {
  interpolate(message: string, values: any[]): string {
    if (message && values) {
      return message.replace(/\$\d+/g, (placeholder) => {
        const value = values[Number(placeholder.slice(1)) - 1].toString()
        if (isoDatePattern.test(value)) {
          const [year, month, day] = value.split('-')
          return `${day}.${month}.${year}`
        } else if (value.includes('translatedName')) {
          return JSON.parse(value).translatedName[language.langKey]
        } else {
          return value
        }
      })
    } else {
      return message ?? ''
    }
  }
}
