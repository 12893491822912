import { getLocale } from '@/utils/LocaleUtils'
import { components } from '@/lib/api/v1'

export const acceptedLanguageCodes = ['de', 'fr', 'it'] as const

export function isAcceptedLanguageCode(languageCode: string): languageCode is AcceptLanguageCode {
  const languageCodeLower: string = languageCode.toLowerCase()
  return acceptedLanguageCodes.some((acceptedLanguageCode) => acceptedLanguageCode === languageCodeLower)
}

/** Returns language of user */
export function getLanguage(): AcceptLanguageCode {
  return getLocale().language as AcceptLanguageCode
}

/** Translate DTO with the current language */
export function translateDto(translateDto: TranslatedDTO): string {
  const currentLanguage = getLanguage()
  // use german as fallback
  return translateDto[currentLanguage] || translateDto['de']
}

export type TranslatedDTO = components['schemas']['TranslatedDTO']
export type AcceptLanguageCode = (typeof acceptedLanguageCodes)[number]
